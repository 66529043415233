import React, { FC } from 'react';

import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import TextBlock from 'gatsby-theme-husky/src/components/TextBlock';
import Quote from 'gatsby-theme-husky/src/components/Quote';
import ArticleBodyImage from 'gatsby-theme-husky/src/components/ArticleBodyImage';
import ArticleSection from 'gatsby-theme-husky/src/components/ArticleSection';
import VideoArticleSection from 'gatsby-theme-husky/src/components/VideoArticleSection';

import { IArticleBodyProps } from 'gatsby-theme-husky/src/components/ArticleBody/model';
import 'gatsby-theme-husky/src/components/ArticleBody/ArticleBody.scss';

const elements = {
  'Article introduction': ({ properties: { articleIntroductionText } }, keyId) => (
    <TextBlock key={keyId} className="article-introduction" html={articleIntroductionText} />
  ),
  'Article section': ({ properties: { articleSectionTitle, articleSectionContent } }, keyId) => (
    <ArticleSection
      key={keyId}
      articleSectionTitle={articleSectionTitle}
      articleSectionContent={articleSectionContent}
    />
  ),
  Quote: ({ properties: { quote } }) => <Quote html={quote} />,
  'Article Body Image': (
    { properties: { articleBodyImageAltText, showArticleBodyImageMobileView, articleBodyImage } },
    keyId
  ) => (
    <ArticleBodyImage
      key={keyId}
      articleBodyImage={articleBodyImage}
      articleBodyImageAltText={articleBodyImageAltText}
      showArticleBodyImageMobileView={showArticleBodyImageMobileView}
    />
  ),
  'Video Section': ({
    properties: {
      videoLink,
      title,
      subTitle,
      isExtendedVideo,
      backgroundVideoImage,
      placeholderYoutube,
      isFullWidth,
      videoDescription,
    },
  }) => (
    <VideoArticleSection
      videoLink={videoLink}
      title={title}
      subTitle={subTitle}
      isExtended={Boolean(Number(isExtendedVideo))}
      isFullWidth={Boolean(Number(isFullWidth))}
      videoDescription={videoDescription}
      backgroundImage={backgroundVideoImage}
      placeholderYoutubeImage={placeholderYoutube}
    />
  ),
};

const ArticleBody: FC<IArticleBodyProps> = ({ content }) => {
  return (
    <div className="article-body">
      <BodyRenderer bodyData={content} bodyStructure={elements} />
    </div>
  );
};

export default ArticleBody;
