import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { Layout, Container, Row, Col } from 'gatsby-theme-husky/src/layout';
import HeroBanner from 'gatsby-theme-husky/src/components/HeroBanner';
import ArticleBody from 'gatsby-theme-husky/src/components/ArticleBody';
import ArticleSubMenu from 'gatsby-theme-husky/src/components/ArticleSubMenu';
import RelatedArticles from 'gatsby-theme-husky/src/components/RelatedArticles';
import { breakpointsArticles } from 'gatsby-theme-husky/src/components/RelatedArticles/constants';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import { TArticlePageComponentProps } from 'gatsby-theme-husky/src/templates/ArticlePage/models';
import './ArticlePage.scss';

const ArticlePage: FC<TArticlePageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    page: {
      url,
      defaultCompositions,
      properties: {
        seoMetaTitle,
        seoCanonicalUrl,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        title,
        articleBodyImageAltText,
        articleHeroBannerMainImageAltText,
        articleBody,
        bodySubnavigation,
        tags,
        topicsTitle,
        relatedArticlesTitle,
        relatedArticlesCTAButtonText,
        relatedArticlesCTAButtonAriaLabel,
      },
      image,
      imageSm,
      imageMd,
    },
    relatedArticles,
  },
}) => {
  const { isMobile, isTablet } = useScreenRecognition();
  const isExtendedVideoSection = articleBody.some(({ properties: { isExtendedVideo } }) =>
    Boolean(Number(isExtendedVideo))
  );

  const ArticleContent = () => (
    <Row>
      {articleBody && bodySubnavigation?.length ? (
        <Col lg="2" xl="2">
          <ArticleSubMenu
            content={articleBody}
            bodySubNavigation={bodySubnavigation}
            tags={tags}
            articleSubMenuTopics={topicsTitle}
          />
        </Col>
      ) : null}
      <Col
        sm="12"
        md="12"
        lg={isExtendedVideoSection ? '12' : '9'}
        xl={isExtendedVideoSection ? '12' : '9'}
      >
        <ArticleBody content={articleBody} />
        {!isExtendedVideoSection ? (
          <RelatedArticles
            breakpoints={breakpointsArticles}
            data={relatedArticles.nodes}
            title={relatedArticlesTitle}
            readMoreText={relatedArticlesCTAButtonText}
            ariaLabel={relatedArticlesCTAButtonAriaLabel || relatedArticlesCTAButtonText}
          />
        ) : null}
      </Col>
    </Row>
  );

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoExternalHreflangs,
      }}
      className={classNames('blog-article-page', {
        'blog-article-page--extended': isExtendedVideoSection,
      })}
    >
      <HeroBanner
        inFirstViewport
        url={url}
        title={title}
        image={isMobile ? imageSm : isTablet ? imageMd : image}
        hasVerticalPadding
        altImageText={articleBodyImageAltText || articleHeroBannerMainImageAltText}
        className="article-listing-hero-banner"
      />
      {!isExtendedVideoSection ? (
        <Container className="blog-article-page__content">
          <ArticleContent />
        </Container>
      ) : (
        <ArticleContent />
      )}
    </Layout>
  );
};

export const query = graphql`
  query ArticlePageMe($url: String = "", $links: [String] = "", $lang: String) {
    page: umbracoArticles(url: { eq: $url }, lang: { eq: $lang }) {
      url
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      image: localImage {
        childImageSharp {
          fluid(maxWidth: 3400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSm: localImage {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMd: localImage {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      properties {
        ...FragmentArticlePageProperties
        seoMetaDescription
        seoMetaTitle
        seoCanonicalUrl
        seoNoIndex
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        relatedArticlesTitle
        tags {
          name
          url {
            url
            name
          }
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $links } }) {
      nodes {
        id
        ...FragmentRelatedArticle
        url
        localImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;
export default ArticlePage;
