import React, { FC } from 'react';
import classNames from 'classnames';
import Video from 'gatsby-theme-husky/src/components/Video';
import BackgroundImage from 'gatsby-background-image';

import { IVideoArticleSectionProps } from './models';
import './VideoArticleSectionExtends.scss';

const VideoArticleSection: FC<IVideoArticleSectionProps> = ({
  videoLink,
  title,
  subTitle,
  isExtended = false,
  isFullWidth = false,
  videoDescription,
  backgroundImage,
  placeholderYoutubeImage,
}) => {
  return isExtended ? (
    <div
      className={classNames('video-article-section__wrapper', {
        'video-article-section__wrapper--full-width': isFullWidth,
      })}
    >
      {backgroundImage?.videoBackgroundImage ? (
        <BackgroundImage
          Tag="section"
          className="video-article-section__background"
          fluid={backgroundImage.videoBackgroundImage.childImageSharp.fluid}
        >
          <Video {...{ placeholderYoutubeImage }} videoURL={videoLink} />
        </BackgroundImage>
      ) : (
        <Video {...{ placeholderYoutubeImage }} videoURL={videoLink} />
      )}
      <div className="video-article__container">
        {title ? <div className="video-article-section__title">{title}</div> : null}
        {subTitle ? <div className="video-article-section__sub-title">{subTitle}</div> : null}
        {videoDescription ? (
          <p className="video-article-section__description">{videoDescription}</p>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="video-article-section__wrapper">
      {title ? <div className="video-article-section__title">{title}</div> : null}
      <Video {...{ placeholderYoutubeImage }} videoURL={videoLink} />
      {subTitle ? <div className="video-article-section__sub-title">{subTitle}</div> : null}
    </div>
  );
};

export default VideoArticleSection;
