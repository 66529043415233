import React, { FC } from 'react';
import ArticleSubMenuItem from 'components/ArticleSubMenuItem';
import TagsList from 'components/TagsList';
import Button from 'common/Button';
import { ArticleSubMenuProps } from './model';
import './ArticleSubMenu.scss';

const ArticleSubMenu: FC<ArticleSubMenuProps> = ({
  content,
  bodySubNavigation,
  tags,
  articleSubMenuTopics,
}) => {
  const articleSection = 'Article section';

  return (
    <div className="article-submenu" data-testid="article-submenu">
      {bodySubNavigation?.[0]?.properties ? (
        <>
          <Button
            className="article-submenu__button"
            ariaLabel=""
            btnColorVariant="outline-dark"
            link={bodySubNavigation[0].properties.subnavigationMenuButtonULR?.[0]?.url || '/'}
          >
            {bodySubNavigation[0].properties.subnavigationMenuButton}
          </Button>
          <h3 className="article-submenu__header">
            {bodySubNavigation[0].properties.subnavigationMenuTitle}
          </h3>
        </>
      ) : null}
      <ul className="article-submenu__list">
        {content
          .filter(({ structure }) => structure === articleSection)
          .map(({ properties: { articleSectionTitle } }) =>
            articleSectionTitle ? (
              <ArticleSubMenuItem
                key={articleSectionTitle}
                name={articleSectionTitle}
                linkToSection={articleSectionTitle}
              />
            ) : null
          )}
      </ul>
      <div className="article-submenu__topics">{articleSubMenuTopics}</div>
      <TagsList dark tags={tags} />
    </div>
  );
};

export default ArticleSubMenu;
